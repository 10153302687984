import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";

import { Button } from "@material-ui/core";
import { Link } from "gatsby";
import { LIVE_URL } from "../../utils/constants";

function AboutSection2({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={8}>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            className={classes.head1}
            style={{ fontSize: isMobile && "24px" }}
          >
            {data?.join_title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h2"
            className={classes.desc1}
            style={{ fontSize: isMobile && "18px" }}
          >
            {data?.join_description}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        style={{
          padding: "1% 7%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          to={`${LIVE_URL}auth?tab=signup`}
          style={{
            textDecoration: "none",
            minWidth: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          target="_self"
        >
          <Button
            className={classes.btn}
            style={{
              height: "70px",
              width: "150px",
              fontSize: isMobile && "15px",
            }}
            aria-label="Join Now"
          >
            Join Now
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default AboutSection2;

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundImage: `url(${coursesHeroImage})`,

    width: "100%",
    minHeight: "150px",
    fontFamily: theme.palette.fontFamily.Reef,
    margin: "2% 0%",
    backgroundColor: "#FFFF",
    borderRadius: "5px",
    boxShadow: " 4px 4px 4px rgba(0, 0, 0, 0.05)",
    // boxShadow: " 0 2px 4px #e3e9f3",
  },
  divider: {
    background: theme.palette.primary.light,
    height: "2px",
    width: "10%",
    marginLeft: "2%",
  },
  desc1: {
    color: "#3f3f3f",
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "22px",
    fontWeight: 900,
    padding: "0% 2%",
    textAlign: "justify",
  },
  head1: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "32px",
    fontWeight: 900,
    padding: "2% 2%",
    color: "#000",
  },
  img1: {
    margin: "2% 2%",
    width: "200px",
    height: "200px",
  },

  btn: {
    background: theme.palette.secondary.light,
    borderRadius: "7px",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    width: "100%",
    height: "81px",
    lineHeight: "28px",
    color: "#FBFBFF",
    textTransform: "none",

    "&:hover": {
      background: theme.palette.secondary.dark,
    },
  },
}));
