import React from "react";
import Layout from "../components/layout/index";
import HeroImageSection from "../components/about/heroImageSection";
import Section1 from "../components/about/section1";
import Section2 from "../components/about/section2";
import Section3 from "../components/about/section3";
import Section4 from "../components/about/section4";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
import { graphql, Link } from "gatsby";
// import ReactMarkdown from "react-markdown"
// import { PICTURE_STRAPI_API_URL } from "../utils/constants"
// import parse, { domToReact } from "html-react-parser"

export default function About({ data }) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const seoData = {
    title: data?.strapiAboutPage?.seo_title,
    description: data?.strapiAboutPage?.seo_description,
    image: data?.strapiAboutMetaData?.image?.publicURL,
    keyword: data?.strapiAboutPage?.seo_tags,
    url: "/about",

  };

  const getId = (url) => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  return (
    <Layout seoData={seoData}>
      <Grid
        container
        justify="center"
        style={{ backgroundColor: "#FBFBFB", paddingTop: "25px" }}
      >
        {/* <Grid item xs={12}>
          <HeroImageSection data={data?.strapiAboutPage} />
        </Grid> */}

        {/* <Grid item xs={12} className={classes.description}>


          <div className="ck-content">
            {data?.strapiAboutPage?.content &&
              parse(data?.strapiAboutPage?.content, {
                replace: domNode => {
                  if (domNode.name && domNode.name === "img") {
                    return React.createElement("img", {
                      src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                      alt: `${domNode?.attribs?.alt}`,
                      style: { paddingLeft: "5%" },
                    })
                  }
                  if (
                    domNode.name &&
                    domNode.name === "a" &&
                    !domNode?.attribs?.href?.includes("mailto:")
                  ) {
                    return (
                      <Link
                        to={
                          domNode?.attribs?.href?.includes("https://")
                            ? domNode?.attribs?.href
                            : `https://${domNode?.attribs?.href}`
                        }
                        target="_blank"
                      >
                        {domToReact(domNode?.children, domNode?.options)}
                      </Link>
                    )
                  }
                  if (domNode.name && domNode.name === "oembed") {
                    let videoId = getId(domNode?.attribs?.url)

                    return (
                      <Grid
                        container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: isMobile ? "200px" : "500px",
                        }}
                      >
                        <iframe
                          width="90%"
                          height={"100%"}
                          title="title"
                          src={`//www.youtube.com/embed/${videoId}`}
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      </Grid>
                    )
                  }
                },
              })}
          </div>
        </Grid> */}
        <Grid
          item
          xs={12}
          style={{ padding: isMobile ? "0px 5px" : "20px 30px" }}
        >
          <Section1 data={data?.strapiAboutPage} />
          <Section2 data={data?.strapiAboutPage} />
          <Section3 data={data?.strapiAboutPage} />
          <Section4 data={data?.strapiAboutPage} />
        </Grid>
      </Grid>
    </Layout>
  );
}

// export default IndexPage

const useStyles = makeStyles((theme) => ({
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    // lineHeight: "151.69%",
    // letterSpacing: "0.045em",
    textAlign: "justify",

    padding: "14px 35px",
  },
}));

export const query = graphql`
  {
    strapiAboutPage {
      title3
      title2
      title1
      title
      join_title
      join_description
      seo_description
      seo_tags
      seo_title
      heroImage {
        publicURL
      }
      description1
      description2
      description3
      image2 {
        publicURL
      }
      image3 {
        publicURL
      }
    }
  }
`;
