import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Typography, useMediaQuery, Divider } from "@material-ui/core"
import { PICTURE_STRAPI_API_URL } from "../../utils/constants"
import parse, { domToReact } from "html-react-parser"
import { Link } from "gatsby"

function AboutSection1({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const getId = url => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    var match = url.match(regExp)

    if (match && match[2].length === 11) {
      return match[2]
    } else {
      return "error"
    }
  }



  return (
    <Grid container className={isMobile ? classes.containerMobile : classes.container}>
      <Grid item xs={12} container>
        <Grid item xs={12} md={3}>
          <Typography
            variant="h1"
            className={classes.head1}
            style={{
              fontSize: isMobile && "24px",
              padding: isMobile && "5% 5% 0% 2%",
            }}
          >
            {data?.title1}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12} md={3} style={{ borderLeft: !isMobile && "1px solid #676767" }}>
          <Typography
            variant="h2"
            className={classes.desc1}
            style={{
              fontSize: isMobile && "18px",
              padding: isMobile && "0% 2%",
            }}
          >
            <div className="ck-content">
              {data?.description1 &&
                parse(data?.description1, {
                  replace: domNode => {
                    console.log(domNode,"domNode")
                    if (domNode.name && domNode.name === "img") {
                      return React.createElement("img", {
                        src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                        alt: `${domNode?.attribs?.alt}`,
                        style: { paddingLeft: "5%" },
                      })
                    }
                    if (
                      domNode.name &&
                      domNode.name === "a" &&
                      !domNode?.attribs?.href?.includes("mailto:")
                    ) {
                      return (
                        <Link
                          to={
                            domNode?.attribs?.href?.includes("https://")
                              ? domNode?.attribs?.href
                              : `https://${domNode?.attribs?.href}`
                          }
                          target="_blank"
                        >
                          {domToReact(domNode?.children, domNode?.options)}
                        </Link>
                      )
                    }
                    if (domNode.name && domNode.name === "oembed") {
                      let videoId = getId(domNode?.attribs?.url)

                      return (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: isMobile ? "200px" : "500px",
                          }}
                        >
                          <iframe
                            width="90%"
                            height={"100%"}
                            title="title"
                            src={`//www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </Grid>
                      )
                    }
                  },
                })}
            </div>
          </Typography>
        </Grid>

        <Grid
            item
            md={6}
            sm={12}
          xs={12}
         // lg={5}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={data?.heroImage?.publicURL}
              alt="about"
              className={classes.img1}
              loading="lazy"
            />
          </Grid>
      </Grid>
    </Grid>
  )
}

export default AboutSection1

const useStyles = makeStyles(theme => ({

  containerMobile: {
    // backgroundImage: `url(${coursesHeroImage})`,

    width: "100%",
    minHeight: "100px",
    fontFamily: theme.palette.fontFamily.Reef,
    margin: "2% 0%",
    backgroundColor: "#FFFF",
    borderRadius: "5px",
    boxShadow: " 4px 4px 4px rgba(0, 0, 0, 0.05)",
    // boxShadow: " 0 2px 4px #e3e9f3",
  },
  container: {
    // backgroundImage: `url(${coursesHeroImage})`,

    width: "100%",
    fontFamily: theme.palette.fontFamily.Reef,
    margin: "2% 0%",
  },
  divider: {
    background: theme.palette.primary.light,
    height: "2px",
    width: "10%",
    marginLeft: "2%",
  },
  desc1: {
    color: "#3f3f3f",
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "22px",
    fontWeight: 900,
    padding: "2% 2%",
    textAlign: "justify",
  },
  head1: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "32px",
    fontWeight: 900,
    padding: "5% 2% 0% 0%",
    display: "flex",
    alignItems: "center",
    color:'#000'
  },
  img1: {
    margin: "2% 2%",
    width: "300px",
    height: "250px",
  },
}))
