import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Typography, useMediaQuery, Divider } from "@material-ui/core"
import { PICTURE_STRAPI_API_URL } from "../../utils/constants"
import parse, { domToReact } from "html-react-parser"
import { Link } from "gatsby"

function AboutSection3({ data }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isXS = useMediaQuery(theme.breakpoints.down("xs"))
  const getId = url => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    var match = url.match(regExp)

    if (match && match[2].length === 11) {
      return match[2]
    } else {
      return "error"
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid item md={8} sm={12} xs={12}>
          <Typography
            variant="h2"
            className={classes.head1}
            style={{ fontSize: isMobile && "24px" }}
          >
            {data?.title3}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: !isMobile && "flex" }}>
        <Grid item md={8} sm={12} xs={12}>
          <Typography
            variant="h2"
            className={classes.desc1}
            style={{ fontSize: isMobile && "18px" }}
          >
            <div className="ck-content">
              {data?.description3 &&
                parse(data?.description3, {
                  replace: domNode => {
                    if (domNode.name && domNode.name === "img") {
                      return React.createElement("img", {
                        src: `${PICTURE_STRAPI_API_URL}${domNode?.attribs?.src}`,
                        alt: `${domNode?.attribs?.alt}`,
                        style: { paddingLeft: "5%" },
                      })
                    }
                    if (
                      domNode.name &&
                      domNode.name === "a" &&
                      !domNode?.attribs?.href?.includes("mailto:")
                    ) {
                      return (
                        <Link
                          to={
                            domNode?.attribs?.href?.includes("https://")
                              ? domNode?.attribs?.href
                              : `https://${domNode?.attribs?.href}`
                          }
                          target="_blank"
                        >
                          {domToReact(domNode?.children, domNode?.options)}
                        </Link>
                      )
                    }
                    if (domNode.name && domNode.name === "oembed") {
                      let videoId = getId(domNode?.attribs?.url)

                      return (
                        <Grid
                          container
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: isMobile ? "200px" : "500px",
                          }}
                        >
                          <iframe
                            width="90%"
                            height={"100%"}
                            title="title"
                            src={`//www.youtube.com/embed/${videoId}`}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </Grid>
                      )
                    }
                  },
                })}
            </div>
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={data?.image3?.publicURL}
            alt="about"
            className={classes.img1}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AboutSection3

const useStyles = makeStyles(theme => ({
  container: {
    // backgroundImage: `url(${coursesHeroImage})`,

    width: "100%",
    minHeight: "360px",
    fontFamily: theme.palette.fontFamily.Reef,
    margin: "2% 0%",
    backgroundColor: "#FFFF",
    borderRadius: "5px",
    boxShadow: " 4px 4px 4px rgba(0, 0, 0, 0.05)",
    // boxShadow: " 0 2px 4px #e3e9f3",
  },
  divider: {
    background: theme.palette.primary.light,
    height: "2px",
    width: "10%",
    marginLeft: "2%",
  },
  desc1: {
    color: "#3f3f3f",
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "22px",
    fontWeight: 900,
    padding: "2% 2%",
    textAlign: "justify",
  },
  head1: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontSize: "32px",
    fontWeight: 900,
    padding: "1% 2%",
    color: "#000",

  },
  img1: {
    margin: "2% 2%",
    width: "250px",
    height: "300px",
  },
}))
